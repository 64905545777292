import {Component, Prop, Watch} from "vue-property-decorator";
import Vue from 'vue'

export type CrudUserProfile = {
  profile: Array<CrudUserProfileAttribute>
}

export type CrudUserProfileAttribute = {
  name: string
  value: any
}

@Component
export class CrudUserProfileMixin extends Vue {
  @Prop({type: Object, required: true}) profile!: CrudUserProfile
}